
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/lrp.twh.frbe",
      function () {
        return require("private-next-pages/lrp.twh.frbe.ts");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/lrp.twh.frbe"])
      });
    }
  